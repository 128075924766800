import { FunctionComponent } from 'react';
import { KoiiTokenIcon, KoiiTokenFallingIcon, FireCoinIcon } from 'assets';
import { usePrevious } from 'hooks';

interface TokensClaimedCounter {
  tokensClaimed: number;
  animate: boolean;
  fireClaimed: boolean;
}

const textBaseStyles =
  'text-aqua text-3xl lg:text-6xl leading-[4rem] font-bold';
const fireTextStyles =
  'text-[#FFC78F] text-xl lg:text-4xl leading-[3rem] font-bold';

export const TokensClaimedCounter: FunctionComponent<TokensClaimedCounter> = ({
  tokensClaimed,
  animate,
  fireClaimed,
}) => {
  const previousTokensClaimed = usePrevious<number>(tokensClaimed);

  return (
    <div className="flex flex-col gap-4 lg:gap-6 ml-auto lg:ml-0 relative h-fit">
      {/* KOII tokens */}
      <div className="relative flex gap-2 lg:gap-6 items-center">
        <span
          className={`${textBaseStyles} absolute left-0 opacity-0 ${
            animate && ' animate-slideUp'
          }`}
        >
          {previousTokensClaimed}
        </span>
        <span className={textBaseStyles}>{tokensClaimed}</span>
        <span className={textBaseStyles}>KOII</span>
        <KoiiTokenIcon className="w-[3rem] lg:w-auto -mt-3 z-10" />
        <KoiiTokenFallingIcon
          className={`w-[3rem] lg:w-auto -mt-3 absolute right-0 opacity-0 ${
            animate && ' animate-slideDown z-0'
          }`}
        />
      </div>

      {/* FIRE tokens, conditionally rendered */}
      {fireClaimed && (
        <div className="relative flex gap-2 lg:gap-6 items-center mt-[-1rem] lg:left-10">
          <span className={fireTextStyles}>5 OFIRE</span>
          <FireCoinIcon className="w-[2.5rem] lg:w-auto -mt-3 z-20 relative -top-4 lg:-top-6 lg:left-2" />
        </div>
      )}
    </div>
  );
};
