import { FunctionComponent, ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary';
  className?: string;
}

const primaryStyles = 'bg-neutral-100 text-[#353570]';
const secondaryStyles = 'text-white';
const disabledStyles = 'bg-aluminium text-[#949494]';

export const Button: FunctionComponent<ButtonProps> = ({
  variant = 'primary',
  className,
  children,
  ...props
}) => {
  const styles = `font-semibold px-10 py-4 rounded-lg border-solid border-neutral-100 border-2 duration-300 ${
    variant === 'primary' ? primaryStyles : secondaryStyles
  } ${props.disabled && disabledStyles} ${className}`;

  return (
    <button className={styles} {...props}>
      {children}
    </button>
  );
};
