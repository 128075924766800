import {
  FunctionComponent,
  ReactNode,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useCookies } from 'react-cookie';

import { Button } from 'components';

import { MethodValidationProps } from 'types';
import { useReauthentication } from 'hooks';

import axios from 'axios';

export const FireValidation: FunctionComponent<MethodValidationProps> = ({
  onSuccess,
  publicKey,
  setSuccessMessage,
}) => {
  const [cookies] = useCookies(['_ga_KC4FPRD34W']);
  let user_cookie: string;

  useEffect(() => {
    try {
      if (cookies['_ga_KC4FPRD34W']) {
        user_cookie = cookies['_ga_KC4FPRD34W'];
      }
    } catch (error) {
      console.log("Error parsing 'user-info' cookie:", error);
    }
  }, [cookies]);
  const [error, setError] = useState<ReactNode>(null);

  async function sendFire() {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/claim-fire`,
        { walletAddress: publicKey }
      );
      setSuccessMessage?.(response.data.message);
    } catch (error) {
      console.log(error);
    }
  }
  const { reauthenticate } = useReauthentication(publicKey);

  const validate = useCallback(async () => {
    await sendFire();
    onSuccess();
    window.dataLayer.push({
      event: 'fire_verification',
      event_name: 'fire_verification',
      user_public_key: publicKey,
      user_cookie: user_cookie,
    });
    reauthenticate();
  }, [onSuccess, publicKey]);

  return (
    <div className="flex flex-col gap-8">
      <p className="text-2xl mt-3">Claim Free OceanFIRE Token</p>
      <p className="text-sm max-w-xs mx-auto">
        Click the button to claim your OceanFIRE Token drop!
      </p>

      <Button className="!p-0 m-auto w-50 h-14" onClick={validate}>
        <a
          rel="noreferrer"
          target="_blank"
          className="w-full h-full flex items-center justify-center"
        >
          Get OceanFIRE
        </a>
      </Button>

      {error && <p className="text-error text-xs -mb-12">{error}</p>}
    </div>
  );
};
