import {
  createContext,
  useState,
  FunctionComponent,
  useContext,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react';

export interface WalletContextValue {
  publicKey: string;
  setPublicKey: Dispatch<SetStateAction<string>>;
  hasVerifiedRecaptcha: boolean;
  setHasVerifiedRecaptcha: Dispatch<SetStateAction<boolean>>;
  discordCode: string;
  setDiscordCode: Dispatch<SetStateAction<string>>;
  githubCode: string;
  setGithubCode: Dispatch<SetStateAction<string>>;
  ownReferralCode: string;
  setOwnReferralCode: Dispatch<SetStateAction<string>>;
}

export const WalletContext = createContext<WalletContextValue | undefined>(
  undefined
);

interface WalletContextProps {
  children: ReactNode;
}

export const WalletContextProvider: FunctionComponent<WalletContextProps> = ({
  children,
}) => {
  const [publicKey, setPublicKey] = useState<string>('');
  const [discordCode, setDiscordCode] = useState<string>('');
  const [githubCode, setGithubCode] = useState<string>('');
  const [ownReferralCode, setOwnReferralCode] = useState<string>('');

  const [hasVerifiedRecaptcha, setHasVerifiedRecaptcha] =
    useState<boolean>(false);

  return (
    <WalletContext.Provider
      value={{
        publicKey,
        setPublicKey,
        discordCode,
        setDiscordCode,
        hasVerifiedRecaptcha,
        setHasVerifiedRecaptcha,
        githubCode,
        setGithubCode,
        ownReferralCode,
        setOwnReferralCode,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWalletContext = () => {
  const context = useContext(WalletContext);

  if (!context) {
    throw new Error(
      `useWalletContext must be used inside WalletContextProvider`
    );
  }
  return context;
};
