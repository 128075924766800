import { FunctionComponent } from 'react';
import Lottie from 'lottie-react';
import confettiAnimation from 'assets/confetti.json';
import { SUCCESS_CONTENT } from 'config';
import { GenericSuccessGraphic } from 'assets';
import { Button } from 'components';
import { useNavigate } from 'react-router-dom';

export type ClaimableTokens = keyof typeof SUCCESS_CONTENT;

interface SuccessScreenProps {
  tokensClaimed: ClaimableTokens;
  isReferralSuccess: boolean;
  // for chains - ethereum, solana etc
  network?: string | undefined;
  koiiKey?: string;
  successMsg?: string;
}

const networks = ['solana', 'ethereum'];

export const SuccessScreen: FunctionComponent<SuccessScreenProps> = ({
  tokensClaimed,
  isReferralSuccess,
  network,
  koiiKey,
  successMsg,
}) => {
  const isNetwork = network && networks.includes(network);
  const isFire = network && network == 'fire';

  const publicKey = koiiKey;
  const navigate = useNavigate();
  const handleClick = () => {
    // Navigate with state
    navigate('/faucet/rewards-history', {
      state: { network, publicKey },
    });
  };

  if (isNetwork || isFire) {
    console.log('Network: ' + network);
    // for chains - ethereum, solana etc
    const content = SUCCESS_CONTENT[network as keyof typeof SUCCESS_CONTENT];
    const Graphic = content.graphic;

    console.log('msg: ', successMsg);
    return (
      <div className="flex flex-col items-center gap-2 relative">
        {isNetwork ? (
          <Graphic
            style={{ transform: 'scale(0.90)', marginTop: '-0.50rem' }}
          />
        ) : (
          <Graphic />
        )}
        <div
          className="max-w-[23rem] text-xl font-semibold mt-1 px-1 z-10"
          style={{ marginTop: '-0.30rem' }}
        >
          {content.title}
        </div>
        <div
          className={`max-w-[21rem] text-sm`}
          style={{ marginTop: '-0.30rem' }}
        >
          {successMsg || content.text}
        </div>

        <Lottie
          animationData={confettiAnimation}
          loop={false}
          className="absolute -top-16 pointer-events-none"
        />
        {isNetwork ? (
          <div>
            <Button className="!p-0 m-auto w-50 h-14" onClick={handleClick}>
              <div className="w-full h-full flex items-center justify-center">
                See Details
              </div>
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  } else {
    console.log('Tokens claimed: ' + tokensClaimed);
    const Graphic =
      isReferralSuccess || !(tokensClaimed in SUCCESS_CONTENT)
        ? GenericSuccessGraphic
        : SUCCESS_CONTENT[tokensClaimed].graphic;
    const title = isReferralSuccess ? (
      <div className="mt-4">
        <p>
          Congrats! Get your 5 extra tokens after validating Twitter or Discord!
        </p>{' '}
      </div>
    ) : !(tokensClaimed in SUCCESS_CONTENT) ? (
      <div className="mt-4">
        <p>Nicely done! You just landed a few more tokens!!</p>{' '}
      </div>
    ) : (
      SUCCESS_CONTENT[tokensClaimed].title
    );
    const text = isReferralSuccess ? (
      <p className="font-normal">
        Get 5 more (for free) when you run a{' '}
        <a
          href="https://www.koii.network/node"
          target="_blank"
          rel="noreferrer"
          className="text-[#9BE7C4] underline cursor-pointer"
        >
          Koii node
        </a>{' '}
        for a week.
      </p>
    ) : (
      SUCCESS_CONTENT[tokensClaimed]?.text
    );

    return (
      <div className="flex flex-col items-center gap-2 relative">
        <Graphic />
        <div className="max-w-[23rem] text-xl font-semibold mt-1 px-1 z-10">
          {title}
        </div>
        <div
          className={`${
            isReferralSuccess ? 'max-w-[21rem]' : 'max-w-[18rem]'
          } text-sm`}
        >
          {text}
        </div>

        <Lottie
          animationData={confettiAnimation}
          loop={false}
          className="absolute -top-16"
        />
      </div>
    );
  }
};
