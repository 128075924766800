import { FunctionComponent } from 'react';
import { OpenNodeGraphic } from 'assets';
import { DOWNLOAD_NODE_URL } from 'config';

export const NodeScreen: FunctionComponent = () => (
  <div className="flex flex-col items-center gap-2 text-left px-10">
    <OpenNodeGraphic className="-mt-24" />
    <p className="mt-3">
      <strong>Head back to the Koii Node </strong>application on your device to
      run tasks and start earning more tokens right away.
    </p>
    <p>
      Each person who runs a node helps make the network stronger, making the
      internet a better place for everyone.
    </p>
    <p className="w-full mt-4">
      Don&apos;t have a node yet?{' '}
      <a
        className="text-[#9BE7C4] underline"
        href={DOWNLOAD_NODE_URL}
        rel="noreferrer"
        target="_blank"
      >
        Download it here.
      </a>
    </p>
  </div>
);
