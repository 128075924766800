import { createBrowserRouter } from 'react-router-dom';
import { Welcome, Faucet, RewardsHistory, NotFound } from 'pages';
import { Layout } from './Layout';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Welcome />,
      },
      {
        path: '/faucet',
        element: <Faucet />,
      },
      {
        path: '/faucet/rewards-history',
        element: <RewardsHistory />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);
