import {
  FunctionComponent,
  ReactNode,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useCookies } from 'react-cookie';
import { Button } from 'components';
import { verifyDiscord } from 'services';
import { useWalletContext } from 'contexts';
import { MethodValidationProps } from 'types';
import { DISCORD_OAUTH_URL } from 'config';
import { parseDiscordError } from 'utils';
import { useReauthentication } from 'hooks';

export const DiscordValidation: FunctionComponent<MethodValidationProps> = ({
  onSuccess,
  publicKey,
}) => {
  const [error, setError] = useState<ReactNode>(null);

  const [cookies] = useCookies(['_ga_KC4FPRD34W']);
  let user_cookie: string;

  useEffect(() => {
    try {
      if (cookies['_ga_KC4FPRD34W']) {
        user_cookie = cookies['_ga_KC4FPRD34W'];
      }
    } catch (error) {
      console.log("Error parsing 'user-info' cookie:", error);
    }
  }, [cookies]);

  const hasCalledVerificationEndpoint = useRef<boolean>(false);

  const { discordCode } = useWalletContext();

  const discordOAuthUrl = `${DISCORD_OAUTH_URL}discord-${publicKey}`;

  const validate = useCallback(async () => {
    const user_discord = await verifyDiscord(discordCode, publicKey);
    onSuccess();
    window.dataLayer.push({
      event: 'discord_verification',
      event_name: 'discord_verification',
      user_public_key: publicKey,
      user_discordName: user_discord?.discordName,
      user_discordEmail: user_discord?.discordEmail,
      user_cookie: user_cookie,
    });
  }, [discordCode, onSuccess, publicKey]);

  const { reauthenticate } = useReauthentication(publicKey);

  const validateWithRetry = useCallback(async () => {
    try {
      hasCalledVerificationEndpoint.current = true;
      await validate();
    } catch (error) {
      const parsedErrorMessage = parseDiscordError(error);
      setError(parsedErrorMessage);
    }
  }, [validate]);

  useEffect(() => {
    if (discordCode && publicKey && !hasCalledVerificationEndpoint.current) {
      validateWithRetry();
    }
  }, [discordCode, onSuccess, publicKey, validateWithRetry]);

  return (
    <div className="flex flex-col gap-8">
      <p className="text-2xl mt-3">Discord Verification</p>
      <p className="text-sm max-w-xs mx-auto">
        Click the button to enter the Koii Discord server and introduce
        yourself!
      </p>

      <Button className="!p-0 m-auto w-50 h-14" onClick={reauthenticate}>
        <a
          rel="noreferrer"
          target="_blank"
          href={discordOAuthUrl}
          className="w-full h-full flex items-center justify-center"
        >
          Go to Discord
        </a>
      </Button>

      {error && <p className="text-error text-xs -mb-12">{error}</p>}
    </div>
  );
};
