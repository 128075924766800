import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Button } from 'components';
import { MethodValidationProps } from 'types';
import axios from 'axios';
import { API_URL } from 'config';
import { getRewardsHistory } from 'services';
import { useNavigate } from 'react-router-dom';

export const BoundTile: FunctionComponent<MethodValidationProps> = ({
  publicKey,
  network,
  onSuccess,
  setSuccessMessage,
}) => {
  const [error, setError] = useState<ReactNode>(null);
  const [hasBeenClicked, setHasBeenClicked] = useState(false);

  interface Reward {
    name: string;
    value: number;
    // add other properties as needed
  }

  const [chainKey, setChainKey] = useState('');
  const [rewards, setRewards] = useState<Reward[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalClaimValue, setTotalClaimValue] = useState(0);
  const [totalNewValue, setTotalNewValue] = useState(0);

  const fetchAndSetStatus = useCallback(async () => {
    if (publicKey) {
      const getUserRewardsHistory = await getRewardsHistory(
        publicKey,
        `${network}`
      );
      const jsonData = await getUserRewardsHistory;

      // save chain key
      setChainKey(jsonData.chainKey);

      // filter rewards that are new
      const newRewards = jsonData.config.filter((reward: any) => reward.new);
      setRewards(newRewards);

      setTotalNewValue(
        newRewards.reduce(
          (acc: number, reward: { value: number }) => acc + reward.value,
          0
        )
      );

      setIsLoading(false);

      // total number of the rewards they claimed
      let totalValue = 0;
      // Check if claimed is true and sum up the count values
      jsonData.config.forEach((item: any) => {
        if (item.claimed) {
          totalValue += item.value;
        }
      });
      setTotalClaimValue(totalValue);
    }
  }, [publicKey, network]);

  useEffect(() => {
    fetchAndSetStatus();
  }, [fetchAndSetStatus]);

  const onClickClaim = useCallback(async () => {
    // don't allow multiple clicks
    setHasBeenClicked(true);

    const keyName = `${network?.toLowerCase()}PublicKey`;
    // Prepare the payload with the public key and the signature
    const payload = {
      [keyName]: chainKey,
      koiiPublicKey: publicKey,
      network: network,
    };

    // Send the payload to the backend
    const response = await axios.post(`${API_URL}/bind-wallet`, payload, {
      // default is to throw error for anything other than 200
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    });

    if (response.status == 200) {
      console.log(response.data.message); // TODO pass to success screen somehow, make sure it has relevant data
      setSuccessMessage?.(response.data.message);
      await onSuccess();
    } else {
      setError(response.data.message);
      setHasBeenClicked(false); // can retry if there is error
    }
  }, [publicKey, chainKey]);

  const navigate = useNavigate();
  const handleClick = () => {
    // Navigate with state
    navigate('/faucet/rewards-history', {
      state: { network, publicKey },
    });
  };

  const connectingReward = 1;
  return (
    <div className="flex flex-col gap-8">
      {isLoading ? (
        <></>
      ) : (
        <>
          <p className="text-2xl mt-3">
            {network && network.charAt(0).toUpperCase() + network.slice(1)}{' '}
            Rewards
          </p>
          {rewards.length > 0 ? (
            <>
              <p className="text-sm max-w-xs mx-auto">
                So far you earned {totalClaimValue + connectingReward} KOII, you
                could get {totalNewValue} more if you own these NFTs:{' '}
                <b>{rewards.map((reward) => reward?.name).join(', ')}</b>
              </p>

              <Button
                className="!p-0 m-auto w-50 h-14"
                onClick={onClickClaim}
                disabled={hasBeenClicked}
              >
                Claim
              </Button>
              {error && <p className="text-error text-xs -mb-12">{error}</p>}
            </>
          ) : (
            <>
              <p className="text-sm max-w-xs mx-auto">
                So far you earned {totalClaimValue + connectingReward} KOII, no
                new claimable items right now.
              </p>
              {/* TODO : If there are new rewards, show which rewards and get rewards otherwise show details button... maybe how many rewards so far */}
              <Button className="!p-0 m-auto w-50 h-14" onClick={handleClick}>
                <div className="w-full h-full flex items-center justify-center">
                  See Details
                </div>
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
};
