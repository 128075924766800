import {
  FunctionComponent,
  useState,
  useCallback,
  MouseEventHandler,
} from 'react';
import { CloseIcon } from 'assets';
import { TASK_GUIDE_URL } from 'config';
import { Button } from 'components';

interface NavigationButtonsProps {
  forceDisplayOptions: boolean;
  onOpenMyNode: () => void;
}

export const NavigationButtons: FunctionComponent<NavigationButtonsProps> = ({
  forceDisplayOptions,
  onOpenMyNode,
}) => {
  const [displayOptions, setDisplayOptions] = useState<boolean>(false);
  const [isOpenMyNodeSelected, setIsOpenMyNodeSelected] =
    useState<boolean>(false);

  const onDone = useCallback(() => setDisplayOptions(true), []);
  const onGoBack: MouseEventHandler = (event) => {
    event.stopPropagation();
    setIsOpenMyNodeSelected(false);
    setDisplayOptions(false);
  };
  const onOpenMyNodeClick = () => {
    setIsOpenMyNodeSelected(true);
    onOpenMyNode();
  };
  const shouldDisplayOptions = forceDisplayOptions || displayOptions;
  const goBackClasses = `${
    isOpenMyNodeSelected && 'col-span-1'
  } col-span-2 justify-self-center flex gap-4 p-4 justify-center items-center`;

  return (
    <div className="w-full lg:w-fit mx-auto lg:mr-0 mt-10 lg:h-[10rem] max-w-120">
      {shouldDisplayOptions ? (
        <div
          onClick={onOpenMyNodeClick}
          className="flex flex-col lg:grid lg:grid-cols-2 mx-auto lg:mx-0 gap-x-9 gap-y-6 w-full md+:w-120"
        >
          <Button
            className={`${isOpenMyNodeSelected && 'hidden'} w-full`}
            variant="secondary"
          >
            Open My Node
          </Button>
          <Button className="w-full h-18 !p-0" variant="primary">
            <a
              href={TASK_GUIDE_URL}
              rel="noreferrer"
              target="_blank"
              className="w-full h-full flex items-center justify-center"
            >
              Developer Tips
            </a>
          </Button>
          {!forceDisplayOptions && (
            <button onClick={onGoBack} className={goBackClasses}>
              <CloseIcon />
              <span>Go Back</span>
            </button>
          )}
        </div>
      ) : (
        <Button className="w-full" onClick={onDone} variant="secondary">
          I&apos;m Done
        </Button>
      )}
    </div>
  );
};
