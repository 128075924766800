import { useEffect, FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { WarningIcon } from 'assets';
import { useWalletContext } from 'contexts';
import { Button } from 'components';
import { DOWNLOAD_FINNIE_URL } from 'config';
import { useK2Finnie, useQueryParam } from 'hooks';
import loadingIcon from 'assets/loading-animation.gif';
import { authenticateUser } from 'services';

export const Welcome: FunctionComponent = () => {
  const [displayRecaptchaError, setDisplayRecaptchaError] =
    useState<boolean>(false);

  const {
    publicKey,
    setPublicKey,
    hasVerifiedRecaptcha,
    setHasVerifiedRecaptcha,
  } = useWalletContext();

  const key = useQueryParam('key');

  const navigate = useNavigate();

  const {
    isFinnieDetected,
    isConnected,
    k2PubKey,
    doesK2AccountExist,
    connect,
  } = useK2Finnie();

  const handleVerify = async (token: string | null) => {
    if (process.env.NODE_ENV === 'development') {
      await authenticateUser('', publicKey);
      setDisplayRecaptchaError(false);
      setHasVerifiedRecaptcha(true);
    } else if (token) {
      try {
        await authenticateUser(token, publicKey);
        setDisplayRecaptchaError(false);
        setHasVerifiedRecaptcha(true);
      } catch (error) {
        console.log({ error });
        setDisplayRecaptchaError(true);
      }
    }
  };

  useEffect(() => {
    if (k2PubKey) {
      setPublicKey(k2PubKey);
    }
    if (key) {
      setPublicKey(key);
    }
  }, [key, k2PubKey, setPublicKey]);

  const titleText = publicKey
    ? `Prove that you’re a unique human: get free KOII.`
    : isFinnieDetected && doesK2AccountExist === false
    ? 'To get KOII from the faucet you need a KOII account.'
    : `You need a KOII account to get your free tokens. Download the Finnie Wallet to get started.`;
  const bodyText = publicKey
    ? `Verify different types of accounts you already have (like an email address). The more methods you use to verify, the more tokens you’ll get.`
    : isFinnieDetected && doesK2AccountExist === false
    ? 'Head to Finnie to create a new KOII account, then come back and refresh the page for your free tokens!'
    : `If you already have Finnie, make sure it is active in this browser window to connect.`;
  const linkToGetFinnie = (
    <a
      rel="noreferrer"
      target="_blank"
      href={DOWNLOAD_FINNIE_URL}
      className="w-full h-full flex items-center justify-center"
    >
      Get Finnie
    </a>
  );
  const handleButtonClick = () => {
    if (publicKey) {
      if (hasVerifiedRecaptcha) {
        navigate('/faucet');
      } else {
        setDisplayRecaptchaError(true);
      }
    } else if (isFinnieDetected && !isConnected) {
      if (doesK2AccountExist === false) {
        navigate(0);
      } else {
        connect();
      }
    } else {
      return;
    }
  };

  const buttonContent = publicKey
    ? 'Get Some KOII'
    : isFinnieDetected && !isConnected
    ? doesK2AccountExist === false
      ? 'Refresh The Page'
      : 'Connect Finnie'
    : linkToGetFinnie;

  const captchaHasNotLoadedYet =
    !hasVerifiedRecaptcha && !displayRecaptchaError;

  return (
    <div className="flex h-full justify-center flex-col lg:flex-row">
      <section className="basis-1/2 xl:basis-[46%] flex items-center justify-center bg-[url('assets/graphics/welcome-ring.svg')] bg-[length:20.6rem] lg:bg-[length:31.2rem] xl:bg-[length:37.5rem] bg-no-repeat bg-center min-h-[25rem] lg:min-h-[43.75rem]">
        <div className="w-100 gap-4 flex flex-col ml-[-1.87rem] scale-50 lg:scale-90 xl:scale-100">
          <h1 className="text-6xl text-aqua text-center leading-[5rem]">
            WELCOME TO THE FAUCET
          </h1>
          <p className="text-2xl">
            Get some tokens to run your node and start earning rewards today.
          </p>
        </div>
      </section>

      <section className="basis-1/2 xl:basis-[46%] flex items-center justify-center pt-8 lg:pt-24 scale-75 lg:scale-90 xl:scale-100">
        <div className="w-110 flex flex-col justify-center gap-9">
          <p className="text-2xl font-semibold">{titleText}</p>

          <div className="text-lg">
            {publicKey && <p>How it works:</p>}
            <p>{bodyText}</p>
          </div>
          <div className="w-64 h-14">
            {captchaHasNotLoadedYet ? (
              <img
                src={loadingIcon}
                className="w-14 h-14 mx-auto"
                alt="loading"
              />
            ) : (
              <Button
                onClick={handleButtonClick}
                disabled={displayRecaptchaError}
                className="w-full"
              >
                {buttonContent}
              </Button>
            )}
          </div>

          {displayRecaptchaError && (
            <div className="flex gap-4 -ml-2 text-[#FFA6A6] text-sm items-center">
              <WarningIcon />
              <div>
                <p>Oh no! It looks like you might be a bot.</p>
                <p>Check to make sure you are human and come back.</p>
              </div>
            </div>
          )}
        </div>
      </section>

      <GoogleReCaptcha onVerify={handleVerify} />
    </div>
  );
};
