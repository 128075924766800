import { FunctionComponent, InputHTMLAttributes } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

export const TextInput: FunctionComponent<Props> = ({
  className,
  ...props
}) => (
  <input
    className={`bg-input-bg border-transparent border-2 focus:outline-none border-solid focus:border-[#5ED9D1] px-3 py-1.5 rounded-lg placeholder:text-aluminium text-[#FFFFFF] text-sm ${className}`}
    {...props}
  />
);
