import { FunctionComponent } from 'react';

export const NotFound: FunctionComponent = () => (
  <div className="flex flex-col items-center justify-center">
    <div className="text-6xl font-extrabold text-gray-100">404</div>
    <div className="text-xl font-medium text-gray-200">Page Not Found</div>
    <div className="mt-4">
      <a href="/" className="text-aqua hover:text-white duration-300">
        Go Back Home
      </a>
    </div>
  </div>
);
