// Tooltip.tsx
import React, { useState } from 'react';
import './Tooltip.css'; // Importing the CSS for styling
import { InfoIcon } from 'assets';

interface TooltipProps {
  message: string;
}

const Tooltip: React.FC<TooltipProps> = ({ message }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="tooltip-container">
      <i
        className="info-icon"
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        <InfoIcon />
      </i>
      {isVisible && <div className="tooltip-box">{message}</div>}
    </div>
  );
};

export default Tooltip;
