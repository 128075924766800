import {
  FunctionComponent,
  KeyboardEventHandler,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useRef,
  RefObject,
} from 'react';

// In case we want to reuse this component for other purposes, these constants could come as props. For now, we reuse it but always with this same structure.
const CODE_LENGTH = 6;
const DIVIDER = 3;

interface CodeInputProps {
  code: string;
  setCode: Dispatch<SetStateAction<string>>;
}

export const CodeInput: FunctionComponent<CodeInputProps> = ({
  code,
  setCode,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const selectedIndex =
    code.length < CODE_LENGTH ? code.length : CODE_LENGTH - 1;

  const handleKeyUp: KeyboardEventHandler = (e) => {
    if (e.key === 'Backspace') {
      setCode((currentcode) => currentcode.slice(0, currentcode.length - 1));
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { value: newCharacter },
  }) => {
    if (code.length < CODE_LENGTH) {
      setCode(code + newCharacter);
    }
  };

  const handleContainerClick = () => inputRef.current?.focus();

  return (
    <div
      className="flex relative w-fit gap-4 scale-75 lg:scale-100"
      onClick={handleContainerClick}
      data-testid="code-input"
    >
      {new Array(CODE_LENGTH).fill(0).map((_, index) => (
        <Cell
          inputRef={inputRef}
          key={index}
          character={code[index]}
          onKeyUp={handleKeyUp}
          onChange={handleChange}
          hasDivider={index === DIVIDER - 1}
          isSelected={selectedIndex === index}
          isFilled={index < code.length}
        />
      ))}
    </div>
  );
};

interface CellProps {
  character: string;
  onKeyUp: KeyboardEventHandler;
  onChange: ChangeEventHandler<HTMLInputElement>;
  hasDivider: boolean;
  isSelected: boolean;
  isFilled: boolean;
  inputRef: RefObject<HTMLInputElement>;
}

export const Cell: FunctionComponent<CellProps> = ({
  character,
  onKeyUp,
  onChange,
  hasDivider,
  isSelected,
  isFilled,
  inputRef,
}) => {
  const styles = `w-12 h-12 m-auto absolute bg-transparent top-0 bottom-0 rounded-lg border-solid duration-300 ${
    isSelected
      ? 'border-4 border-input-border'
      : isFilled
      ? 'border-2 border-input-border'
      : 'border border-white'
  } caret-transparent p-4 focus:outline-none`;

  return (
    <div className="rounded-lg flex">
      <div className="w-12 h-12 text-2xl m-auto rounded-lg caret-transparent flex items-center justify-center">
        {character}
      </div>
      <input
        value=""
        ref={inputRef}
        onChange={onChange}
        onKeyUp={onKeyUp}
        className={styles}
      />
      {hasDivider && (
        <div className="-mx-2 w-5 border-t-2 border-white border-solid h-0 justify-self-center self-center ml-2" />
      )}
    </div>
  );
};
