import { FunctionComponent } from 'react';
import { FinnieLogo } from 'assets';

export const Header: FunctionComponent = () => (
  <header className="w-full sticky">
    <div className="flex items-center px-4 gap-2">
      <FinnieLogo />
      <h5 className="text-2xl font-semibold">Koii</h5>
    </div>
  </header>
);
