import React, { useState, ReactNode } from 'react';
import './Tooltip.css'; // Importing the CSS for styling

interface TooltipProps {
  message: string;
  children?: ReactNode; // Accepting children to wrap other elements
}

const ToolTipWrap: React.FC<TooltipProps> = ({ message, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      className="tooltip-container"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && <div className="tooltip-box">{message}</div>}
    </div>
  );
};

export default ToolTipWrap;
