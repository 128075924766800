import { AxiosError } from 'axios';
import { FunctionComponent, useState } from 'react';
import { CheckIcon } from 'assets';
import { Button } from './common';
import { submitReferralCode } from 'services';
import { ValidationStatus } from 'types';
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ReferralCodeProps {
  status: ValidationStatus;
  publicKey: string;
  onSuccess: () => void;
  onFocus: () => void;
}

export const ReferralCode: FunctionComponent<ReferralCodeProps> = ({
  status,
  publicKey,
  onSuccess,
  onFocus,
}) => {
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [cookies, setCookies] = useCookies(['user-info']);
  const [userCookies, setUserCookies] = useCookies(['_ga_KC4FPRD34W']);
  const [autoFilled, setAutoFilled] = useState(false);
  let user_cookie: string;
  useEffect(() => {
    try {
      if (userCookies['_ga_KC4FPRD34W']) {
        user_cookie = userCookies['_ga_KC4FPRD34W'];
      }
    } catch (error) {
      console.error("Error parsing 'user-info' cookie:", error);
    }
  }, [userCookies]);

  useEffect(() => {
    try {
      if (cookies['user-info']) {
        const userReferrer = cookies['user-info'].referrerCode;
        setCode(userReferrer);
        setAutoFilled(true);
        if (userReferrer !== null) {
          toast.success(
            'Welcome! A referral code has been entered for you. Check now to earn exclusive benefits!',
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            }
          );
          validateCode(userReferrer);
        }
      }
    } catch (error) {
      console.error("Error parsing 'user-info' cookie:", error);
    }
  }, [cookies]);

  const changeCode: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value: newCode },
  }) => {
    if (autoFilled) {
      setAutoFilled(false);
    }
    setError('');
    setCode(newCode);
  };

  const validateCode = async (code: string) => {
    setError('');
    try {
      await submitReferralCode(code, publicKey);
      onSuccess();
      window.dataLayer.push({
        event: 'referral_code',
        event_name: 'referral_code',
        user_public_key: publicKey,
        referral_code: code,
        user_cookie: user_cookie,
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        setError(error.response?.data.message);
      }
    }
  };

  const hasBeenClaimed = status === ValidationStatus.CLAIMED;

  const inputClasses = `placeholder:font-normal placeholder:text-white/90 font-semibold w-full mb-7 p-4 max-w-full focus:bg-input-border/40 focus:outline-none rounded border-solid border-white/[.35] border-2 shadow-tile ${
    hasBeenClaimed ? 'bg-methods-claimed' : 'bg-methods-notClaimed'
  }`;
  const buttonClasses = `!h-[59.2px] !w-24 ${
    hasBeenClaimed ? 'bg-[#9BE7C4] border-[#9BE7C4]' : ''
  }`;

  const inputValue = hasBeenClaimed ? 'Referral claimed!' : code;

  return (
    <div className="w-full" onClick={onFocus}>
      <ToastContainer />
      <div className="w-full flex justify-between gap-4">
        <input
          className={inputClasses}
          value={inputValue}
          onChange={changeCode}
          disabled={hasBeenClaimed}
          placeholder="Enter your referral code"
        />
        <Button
          className={buttonClasses}
          onClick={() => validateCode(code)}
          disabled={hasBeenClaimed}
          data-testid="verify-referral-code"
        >
          <CheckIcon />
        </Button>
      </div>
      {error && (
        <p className="text-error text-sm mr-auto -mt-6 h-8 font-semibold whitespace-nowrap">
          {error}
        </p>
      )}
    </div>
  );
};
