import React from 'react';
import { CheckCircleIcon, ExclamationCircleIcon } from 'assets';
import Tooltip from './common/ToolTip';
import ToolTipWrap from './common/ToolTipWrap';

interface Reward {
  _id: string;
  name: string;
  value: string;
  status: string;
  new: boolean;
  claimed: boolean;
  expiry: string;
}

interface RewardsHistoryList {
  rewards: Reward[];
}

enum RewardStatus {
  New = 'New',
  Expired = 'Expired',
  Claimed = 'Claimed',
  NotOwned = 'Not Eligible',
}
const getStatus = (reward: Reward): any => {
  const currentDate = new Date();
  const expiryDate = new Date(reward.expiry);

  if (reward.new) {
    return (
      <div className="flex gap-4 text items-center text-[#FFC78F]">
        <ToolTipWrap message="You haven't checked if you own it yet, if you do you can be rewarded!">
          <ExclamationCircleIcon
            style={{ width: '40px', height: '40px', fill: '#FFC78F' }}
          />
        </ToolTipWrap>
        {RewardStatus.New}
      </div>
    );
  }

  if (expiryDate < currentDate && !reward.claimed) {
    return (
      <div className="flex gap-4 items-center text-red-200">
        <ToolTipWrap message="The claimable period is over.">
          <ExclamationCircleIcon
            style={{ width: '40px', height: '40px', fill: '#FFC78F' }}
          />
        </ToolTipWrap>
        {RewardStatus.Expired}
      </div>
    );
  }

  if (reward.claimed && expiryDate >= currentDate) {
    return (
      <div className="flex gap-4 items-center text-[#9BE7C4]">
        <ToolTipWrap message="You got this reward because you owned the asset!">
          <CheckCircleIcon style={{ width: '40px', height: '40px' }} />
        </ToolTipWrap>
        {RewardStatus.Claimed}
      </div>
    );
  }

  return (
    <div className="flex gap-4 items-center text-gray-400">
      <ToolTipWrap message="You don't own the asset so no reward!">
        <ExclamationCircleIcon
          style={{
            width: '40px',
            height: '40px',
            fill: 'rgb(156, 163, 175)',
          }}
        />
      </ToolTipWrap>
      {RewardStatus.NotOwned}
    </div>
  );
};

export const RewardsHistoryTable: React.FC<RewardsHistoryList> = ({
  rewards,
}) => {
  return (
    <div className="overflow-x-auto">
      <table className="w-full text-left my-5">
        <thead>
          <tr className="text-aqua text-1xl md:text-2xl font-semibold">
            <th className="py-2 px-4">Assets</th>
            <th className="py-2 px-4">Reward</th>
            <th className="py-2 px-4">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr key={'connected'}>
            <td className="py-2 px-4">{'Connected Wallet!'}</td>
            <td className="py-2 px-4">{1}</td>
            <td className="py-2 px-4">
              {' '}
              <div className="flex gap-4 items-center text-[#9BE7C4]">
                {/* <Tooltip message="You got this reward because you connected your wallet!" /> */}
                <CheckCircleIcon style={{ width: '40px', height: '40px' }} />
                {RewardStatus.Claimed}
              </div>
            </td>
          </tr>
          {rewards.length > 0 ? (
            rewards.map((reward) => (
              <tr key={reward._id}>
                <td className="py-2 px-4">{reward.name}</td>
                <td className="py-2 px-4">{reward.value}</td>
                <td className="py-2 px-4">{getStatus(reward)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="py-2 px-4 text-aqua" colSpan={3}>
                Check back for new rewards!
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
