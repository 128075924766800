import { AxiosError } from 'axios';

export * from './testing';
export * from './errorParsing';

export const isValidEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

export const verifyIfJwtHasExpired = (error: unknown) => {
  if (error instanceof AxiosError) {
    const isJwtExpired =
      !!error.response?.data?.message?.includes('Unauthorized');

    return isJwtExpired;
  }
  return false;
};
