import { FunctionComponent, useState } from 'react';
import { CloseIcon, MethodsGraphic } from 'assets';
import {
  Tile,
  TwitterValidation,
  EmailValidation,
  FireValidation,
  DiscordValidation,
  SuccessScreen,
  ClaimableTokens,
  NodeScreen,
  ReferralTile,
  SolanaValidation,
  EthereumValidation,
} from 'components';
import { MethodName } from 'types';
import { BoundTile } from './BoundTile';

const METHOD_COMPONENTS = {
  twitter: TwitterValidation,
  email: EmailValidation,
  fire: FireValidation,
  discord: DiscordValidation,
  referral: ReferralTile,
  solana: SolanaValidation,
  ethereum: EthereumValidation,
  bound: BoundTile,
};

interface CurrentValidationTileProps {
  method?: MethodName;
  publicKey: string;
  reset: () => void;
  onSuccessfulValidation: () => Promise<void>;
  tokensClaimed: ClaimableTokens;
  displayNodeScreen: boolean;
  displaySuccessScreen: boolean;
  isReferralSuccess: boolean;
  // for chains - ethereum, solana etc
  network?: string;
  msg: string;
}

export const CurrentValidationTile: FunctionComponent<
  CurrentValidationTileProps
> = ({
  method,
  publicKey,
  reset,
  tokensClaimed,
  onSuccessfulValidation,
  displayNodeScreen,
  displaySuccessScreen,
  isReferralSuccess,
  network,
  msg,
}) => {
  const MethodComponent = !!method && METHOD_COMPONENTS[method];
  const [successMsg, setSuccessMessage] = useState('');

  if (msg) {
    console.log('Current Val msg', msg);
    setSuccessMessage(msg);
  }
  return (
    <Tile className="w-120 !min-h-[28rem] md:!min-h-[25rem] flex flex-col justify-center items-center gap-4 relative">
      {displayNodeScreen ? (
        <NodeScreen />
      ) : MethodComponent ? (
        <>
          <CloseIcon
            className="absolute right-3 top-3 cursor-pointer"
            onClick={reset}
          />
          <MethodComponent
            onSuccess={onSuccessfulValidation}
            publicKey={publicKey}
            network={network}
            setSuccessMessage={setSuccessMessage}
          />
        </>
      ) : displaySuccessScreen ? (
        //    <Tile className="w-120 !min-h-[28rem] md:!min-h-[25rem] flex flex-col justify-center items-center gap-4 relative">
        <SuccessScreen
          tokensClaimed={tokensClaimed}
          isReferralSuccess={isReferralSuccess}
          network={network}
          koiiKey={publicKey}
          successMsg={successMsg}
        />
      ) : (
        // </Tile>
        <div className="pt-11 pb-8">
          <div>
            <p className="text-xl font-semibold">
              Select a verification method!
            </p>
            <p className="text-xl font-semibold mb-4">to get some free KOII.</p>
          </div>
          <MethodsGraphic className="max-w-[265px] mx-auto w-full" />
          <p className="w-full font-normal text-lg leading-6 max-w-[390px] mx-auto px-4">
            One verification grants enough tokens to begin, but the more
            verifications you complete, the more you earn!
          </p>
        </div>
      )}
    </Tile>
  );
};
