import { ReactNode } from 'react';
import { ActionMeta } from 'react-select';
import { PublicKey } from '@_koii/web3.js';

declare global {
  interface Window {
    k2?: {
      isConnected: boolean;
      isFinnie: boolean;
      isPhantom: boolean;
      publicKey: string | null;
      connect(): Promise<PublicKey>;
      disconnect(): Promise<string>;
    };
  }
}

export interface MethodValidationProps {
  onSuccess: () => Promise<void>;
  publicKey: string;
  network?: string; // added for boundTile
  setSuccessMessage?: (value: string) => void;
}

export interface CountryCodeOption {
  label: ReactNode;
  value: string;
}

export type OnCountryCodeChange = (
  option: CountryCodeOption | null,
  actionMeta: ActionMeta<CountryCodeOption>
) => void;

export enum ValidationStatus {
  CLAIMED = 'CLAIMED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_CLAIMED = 'NOT_CLAIMED',
  FAILED = 'FAILED',
  BOUND = 'BOUND',
}

export type MethodName =
  | 'discord'
  | 'email'
  | 'fire'
  | 'twitter'
  | 'referral'
  | 'solana'
  | 'ethereum'
  | 'bound';
