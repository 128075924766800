import { WarningIcon } from 'assets';
import { Button } from './common';
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { postClaimRewards } from 'services';
import Spinner from './common/Spinner';

interface ClaimRewardsCodeProps {
  chainKey: string;
  rewards: any[];
  koiiPublicKey: string;
  setUpdateStatus: Dispatch<SetStateAction<boolean>>;
  setClaimMessage: Dispatch<SetStateAction<string>>;
}

export const ClaimRewardsCode: FunctionComponent<ClaimRewardsCodeProps> = ({
  chainKey,
  rewards,
  koiiPublicKey,
  setUpdateStatus,
  setClaimMessage,
}) => {
  const [rewardName, setRewardName] = useState('');
  useEffect(() => {
    rewards.forEach((item: any) => {
      if (item.new) {
        setRewardName(item.network);
      }
    });
  }, [rewards]);

  const hasBeenClaimed = rewardName ? false : true;

  const inputClasses = `font-semibold w-full mb-7 !h-[70px] p-4 max-w-full focus:bg-input-border/40 focus:outline-none rounded border-solid border-white/[.35] border-2 shadow-tile bg-methods-notClaimed`;
  const buttonClasses = `flex items-center justify-center !h-[70px] min-w-[120px] ${
    hasBeenClaimed ? 'bg-[#9BE7C4] border-[#9BE7C4]' : ''
  }`;

  const [error, setError] = useState<string>('');
  const [claiming, setClaiming] = useState(false);

  const ClaimRewardsCode = async () => {
    setError('');
    setClaiming(true);
    const claimRewards = await postClaimRewards(
      chainKey,
      koiiPublicKey,
      rewardName
    );

    console.log(claimRewards);

    if (claimRewards.status !== 201 && claimRewards.status !== 200) {
      setError(claimRewards.message);
      setUpdateStatus(true);
      setClaiming(false);
      return;
    }

    setClaimMessage(claimRewards.message);
    setUpdateStatus(true);
    setClaiming(false);
  };

  return (
    <div className="w-full">
      <div className="w-full flex justify-between gap-4">
        <input
          className={inputClasses}
          value={chainKey}
          disabled
          readOnly={true}
        />
        {claiming ? (
          <Button className={buttonClasses} disabled={true}>
            <Spinner />
            <span className="ml-2">Claiming...</span>
          </Button>
        ) : (
          <Button
            className={buttonClasses}
            onClick={ClaimRewardsCode}
            disabled={hasBeenClaimed}
            data-testid="verify-referral-code"
          >
            {hasBeenClaimed ? 'Nothing to Claim' : 'Claim'}
          </Button>
        )}
      </div>

      {error ? (
        <p className="text-error text-sm mr-auto -mt-6 h-8 font-semibold whitespace-nowrap flex items-center">
          <WarningIcon
            style={{
              width: '40px',
              height: '40px',
            }}
          />
          {error}
        </p>
      ) : (
        !hasBeenClaimed && <p className="text-sm">No signing required</p>
      )}
    </div>
  );
};
