import { FunctionComponent, InputHTMLAttributes } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

export const CheckBox: FunctionComponent<Props> = ({
  className = '',
  ...props
}) => {
  const classes = `cursor-pointer appearance-none shrink-0 w-5 h-5 rounded-sm bg-white focus:outline-none checked:bg-aqua checked:border-0 checked:bg-[url('./assets/icons/checkbox-check.svg')] checked:bg-no-repeat checked:bg-center checked:bg-[length:10px_10px]
disabled:border-steel-400 disabled:bg-steel-400 ${className}`;

  return <input type="checkbox" className={classes} {...props} />;
};
