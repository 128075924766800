import { FunctionComponent } from 'react';
import Select, { StylesConfig, GroupBase } from 'react-select';
import { COUNTRIES } from 'config';
import { CountryCodeOption, OnCountryCodeChange } from 'types';

const options: CountryCodeOption[] = COUNTRIES.map((country) => ({
  value: country.dialCode,
  label: (
    <div className="flex gap-4 text-aluminium text-sm">
      <span>{country.flag}</span>
      <span>{country.dialCode}</span>
      <span className="text-aluminium text-sm">{country.name}</span>
    </div>
  ),
}));

const styles: StylesConfig<
  CountryCodeOption,
  false,
  GroupBase<CountryCodeOption>
> = {
  option: (provided) => ({
    ...provided,
    color: '#D6D6D6',
    textAlign: 'left',
    backgroundColor: 'rgba(133, 133, 188, 0.2)',
    '&:hover': {
      backgroundColor: 'rgba(133, 133, 188, 0.4)',
    },
    fontSize: '0.875rem',
  }),
  menu: (provided) => ({
    ...provided,
    textAlign: 'left',
    backgroundColor: '#4c6188',
    borderRadius: '0.5rem',
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: '#4c6188',
    borderRadius: '0.5rem',
  }),
  control: (provided, state) => ({
    ...provided,
    width: '17rem',
    backgroundColor: 'rgba(133, 133, 188, 0.2)',
    borderRadius: '0.5rem',
    borderColor: state.isFocused ? '#5ED9D1' : 'transparent',
    boxShadow: state.isFocused ? '0 0 0 2px #5ED9D1' : 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#D6D6D6',
    textAlign: 'left',
    fontSize: '0.875rem',
  }),
  indicatorSeparator: () => ({
    backgroundColor: 'transparent',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#D6D6D6',
    '&:hover': {
      color: '#5ED9D1',
    },
  }),
  input: (provided) => ({
    ...provided,
    color: '#D6D6D6',
    textAlign: 'left',
    fontSize: '0.875rem',
  }),
};

interface CountryCodeDropdownProps {
  onChange: OnCountryCodeChange;
}

export const CountryCodeDropdown: FunctionComponent<
  CountryCodeDropdownProps
> = ({ onChange }) => {
  const placeholder = (
    <div className="flex gap-4 text-aluminium text-sm">
      <span>{COUNTRIES[0].flag}</span>{' '}
      <span className="text-aluminium text-sm">Enter country code</span>
    </div>
  );

  return (
    <Select
      options={options}
      styles={styles}
      onChange={onChange}
      placeholder={placeholder}
      noOptionsMessage={() => 'Unknown country code'}
    />
  );
};
