import { FunctionComponent, ReactNode, useState } from 'react';
import { Button } from 'components';
import { MethodValidationProps } from 'types';

// Solana wallet related imports
import { FC, useMemo, useCallback } from 'react';
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { UnsafeBurnerWalletAdapter } from '@solana/wallet-adapter-wallets';
import {
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton,
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Buffer } from 'buffer';
window.Buffer = Buffer;

// wallets
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import { CoinbaseWalletAdapter } from '@solana/wallet-adapter-coinbase';
import { LedgerWalletAdapter } from '@solana/wallet-adapter-ledger';

import { ed25519 } from '@noble/curves/ed25519';
import axios from 'axios';
import { API_URL } from 'config';
import Spinner from './common/Spinner';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

// Tutorial I followed: https://github.com/anza-xyz/wallet-adapter/blob/master/APP.md

export const SolanaValidation: FunctionComponent<MethodValidationProps> = ({
  onSuccess,
  publicKey,
  setSuccessMessage,
}) => {
  const [error, setError] = useState<ReactNode>(null);
  const koiiPublicKey = publicKey;
  const [connected, setConnected] = useState<ReactNode>(false);

  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      /**
       * Wallets that implement either of these standards will be available automatically.
       *
       *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
       *     (https://github.com/solana-mobile/mobile-wallet-adapter)
       *   - Solana Wallet Standard
       *     (https://github.com/anza-xyz/wallet-standard)
       *
       * If you wish to support a wallet that supports neither of those standards,
       * instantiate its legacy wallet adapter here. Common legacy adapters can be found
       * in the npm package `@solana/wallet-adapter-wallets`.
       */
      // backpack
      new SolflareWalletAdapter(),
      new PhantomWalletAdapter(),
      new CoinbaseWalletAdapter(),
      new LedgerWalletAdapter(),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network]
  );
  const multiButtonStyle = {
    backgroundColor: 'white',
    color: '#353570',
    border: '1px solid #353570',
    padding: '1rem 2.5rem', // equivalent to py-4 px-10 in TailwindCSS
    borderRadius: '0.5rem', // equivalent to rounded-lg in TailwindCSS
    width: '200px', // assuming w-50 is 200px as an example
    height: '56px', // assuming h-14 is 56px as an example
    fontFamily: 'Sora, Open Sans, ui-sans-serif, system-ui',
  };

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <div className="flex flex-col gap-8">
            <p className="text-2xl mt-3">Solana Verification</p>
            <div className="text-sm max-w-xs mx-auto">
              <p>Scan your wallet to get KOII!</p>
              <p className="mt-1">Based on NFTs owned</p>{' '}
            </div>
            <ul>
              <li>1. Access your wallet.</li>
              <li>2. Bind your SOL wallet.</li>
              <li>3. Enjoy your rewards!</li>
            </ul>
            {!connected && (
              <div className="mx-auto w-fit">
                <WalletMultiButton style={multiButtonStyle} />
              </div>
            )}
            <SignSOLMessage
              koiiPublicKey={koiiPublicKey}
              onSuccess={onSuccess}
              setError={setError}
              setMsg={setSuccessMessage}
              setConnected={setConnected}
            />

            {error && <p className="text-error text-xs my-0">{error}</p>}
            {/* {connected && (
              <div className="mx-auto w-fit">
                <WalletDisconnectButton style={multiButtonStyle} />
              </div>
            )} */}
          </div>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

interface SignSOLMessageProps {
  koiiPublicKey: string;
  onSuccess: () => Promise<void>;
  setError: (error: ReactNode) => void;
  setMsg?: (value: string) => void;
  setConnected: (connected: ReactNode) => void;
}

const SignSOLMessage: FC<SignSOLMessageProps> = ({
  koiiPublicKey,
  onSuccess,
  setError,
  setMsg,
  setConnected,
}) => {
  const { publicKey, signMessage, disconnect } = useWallet();
  const [hasBeenClicked, setHasBeenClicked] = useState(false);

  if (publicKey) {
    setConnected(true);
  }

  const onClick = useCallback(async () => {
    // don't allow multiple clicks
    setHasBeenClicked(true);
    try {
      if (!publicKey || !signMessage) throw new WalletNotConnectedError();

      const message = new TextEncoder().encode(
        `Koii wants you to connect your Solana account:\n${publicKey.toBase58()}\n\n with your Koii account ${koiiPublicKey}\n\n No payment, just verify you own the wallet\n\n`
      );
      console.log('Message: ', message);
      const signature = await signMessage(message);
      console.log('Signature: ', signature);

      if (!ed25519.verify(signature, message, publicKey.toBytes())) {
        throw new Error('Message signature invalid!');
      }

      // Prepare the payload with the public key and the signature
      const payload = {
        solanaPublicKey: publicKey.toString(),
        signature: Buffer.from(signature).toString('hex'),
        koiiPublicKey: koiiPublicKey,
        network: 'Solana',
      };
      console.log('Payload: ', payload);
      // Send the payload to the backend
      const response = await axios.post(`${API_URL}/bind-wallet`, payload, {
        // default is to throw error for anything other than 200
        validateStatus: function (status) {
          return status < 500; // Resolve only if the status code is less than 500
        },
      });
      console.log(response.data.message);

      if (response.status == 200) {
        console.log('Successfully connected Solana!');
        setMsg?.(response.data.message);
        await onSuccess();

        // we no longer need the wallet connected
        disconnect().catch(() => {
          // Silently catch because any errors are caught by the context `onError` handler
        });
      } else {
        setError(response.data.message);
        setHasBeenClicked(false); // can retry if there is error
      }
    } catch (error: any) {
      console.error('Caught Error: ', error.message);
      setError(error.message);
      setHasBeenClicked(false); // can retry if there is error
    }
  }, [publicKey, signMessage, koiiPublicKey]);

  return (
    <>
      {publicKey && (
        <Button
          className="!p-0 m-auto w-50 h-14"
          onClick={onClick}
          disabled={!publicKey || hasBeenClicked}
          id="signSOL"
        >
          {hasBeenClicked ? <Spinner /> : 'Connect Wallet!'}
        </Button>
      )}
    </>
  );
};
