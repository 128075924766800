import { AxiosError } from 'axios';
import {
  DISCORD_EMAIL_VERIFICATION_URL,
  KOII_DISCORD_INVITE_URL,
} from 'config';

export const parseAxiosError = (
  error: unknown,
  substringInError: string,
  messageToDisplay: string
) => {
  const errorMessage =
    error instanceof AxiosError
      ? error.response?.data.message
      : (error as string);

  const parsedErrorMesssage = errorMessage?.includes?.(substringInError)
    ? messageToDisplay
    : '';

  return parsedErrorMesssage;
};

export const parseDiscordError = (error: unknown) => {
  const genericErrorMessage = 'Discord verification failed. Try again.';

  if (error instanceof AxiosError) {
    const errorMessage: string = error.response?.data?.message?.includes(
      "You didn't get KOII from faucet as you already received"
    ) ? (
      'This Discord account has already been verified by the faucet.'
    ) : error.response?.data?.message?.includes(
        'Please join Koii discord channel to claim KOII'
      ) ? (
      <span>
        It looks like you aren&apos;t part of the Koii Discord yet! <br />
        Join us now at{' '}
        <a
          className="underline font-semibold"
          target="_blank"
          rel="noreferrer"
          href={KOII_DISCORD_INVITE_URL}
        >
          {' '}
          {KOII_DISCORD_INVITE_URL}
        </a>
        .
      </span>
    ) : error.response?.data?.message?.includes(
        'Please verify your discord email to claim KOII'
      ) ? (
      <span>
        It looks like your Discord account was never verified with Discord.
        <br />
        Check out{' '}
        <a
          className="underline font-semibold"
          target="_blank"
          rel="noreferrer"
          href={DISCORD_EMAIL_VERIFICATION_URL}
        >
          this link
        </a>{' '}
        if you need help, then come back here to confirm.
      </span>
    ) : (
      error.response?.data?.message || genericErrorMessage
    );

    return errorMessage;
  }
  return genericErrorMessage;
};
