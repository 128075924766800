import { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Button,
  ClaimRewardsCode,
  ClaimRewardsTile,
  TokensClaimedCounter,
} from 'components';
import { RewardsHistoryTable } from 'components/RewardsHistoryList';
import { getRewardsHistory } from 'services';
import Spinner from 'components/common/Spinner';
import { useWalletContext } from 'contexts';
import { useNavigate } from 'react-router-dom';
import { BackIcon, WarningIcon } from 'assets';

export const RewardsHistory: FunctionComponent = () => {
  const [animateCounter, setAnimateCounter] = useState<boolean>(false);
  const [chainKey, setChainKey] = useState('');

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  // const network = urlParams.get('network');
  // const koiiPublicKey = urlParams.get('koiiKey');

  const { network, publicKey } = location.state || {};

  const [rewards, setRewards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalClaimValue, setTotalClaimValue] = useState(0);

  const [updateStatus, setUpdateStatus] = useState(false);
  const [claimMessage, setClaimMessage] = useState('');

  const tokenName = { ethereum: 'ETH', solana: 'SOL' };

  const koiiPublicKey = publicKey;

  const navigate = useNavigate();

  const fetchAndSetStatus = useCallback(async () => {
    setIsLoading(true);
    if (koiiPublicKey) {
      const getUserRewardsHistory = await getRewardsHistory(
        koiiPublicKey,
        `${network}`
      );
      const jsonData = await getUserRewardsHistory;

      console.log('Got data: ', jsonData);

      // save chain key
      setChainKey(jsonData.chainKey);

      // sort and save rewards
      const sortedRewards = jsonData.config.sort((a: any, b: any) => {
        return b.new - a.new || b.claimed - a.claimed;
      });
      setRewards(sortedRewards);
      setIsLoading(false);

      // total number of the rewards they claimed
      let totalValue = 1;
      // Check if claimed is true and sum up the count values
      jsonData.config.forEach((item: any) => {
        if (item.claimed) {
          totalValue += item.value;
        }
      });
      setTotalClaimValue(totalValue);
    }
  }, [koiiPublicKey, network]);

  useEffect(() => {
    fetchAndSetStatus();
  }, [fetchAndSetStatus]);

  useEffect(() => {
    if (updateStatus) {
      setIsLoading(true);
      fetchAndSetStatus();
    }
  }, [fetchAndSetStatus, updateStatus]);

  const handleButtonClick = () => {
    if (publicKey) {
      navigate('/faucet');
    } else {
      navigate('/');
    }
  };

  return (
    <div className="py-4 px-8 lg:px-[4.3rem] xl:px-[8%] 3xl:px-[12%] 4xl:px-[20%] 5xl:px-[25%]">
      <section className="mb-14 flex justify-between flex-col-reverse lg:flex-row">
        <div className="w-full md:max-w-[60rem] md:w-[65%]">
          <h4 className="flex items-center text-aqua text-5xl md:text-6xl font-bold">
            <button
              className="flex items-center justify-center mr-2"
              onClick={handleButtonClick}
            >
              <BackIcon />
            </button>
            AIRDROP
          </h4>
          <p className="mt-4 text-lg leading-6 max-w-[514px]">
            Verify your{' '}
            {network && tokenName[network as keyof typeof tokenName]} assets
          </p>
        </div>
      </section>
      <section className="min-w-full sm:min-w-fit w-[90%] lg:w-1/2 max-w-[35.6rem] mx-auto lg:mx-0">
        <ClaimRewardsCode
          chainKey={chainKey}
          rewards={rewards}
          koiiPublicKey={`${koiiPublicKey}`}
          setUpdateStatus={setUpdateStatus}
          setClaimMessage={setClaimMessage}
        />
      </section>
      <section className="flex flex-col lg:flex-row gap-20 lg:gap-6 items-center h-full m-auto justify-between">
        <div className="grid grid-cols-1 min-w-full sm:min-w-fit w-[90%] lg:w-1/2 max-w-[35.6rem] gap-7">
          {isLoading ? <Spinner /> : <RewardsHistoryTable rewards={rewards} />}
          {/* <button className="m-auto" onClick={handleButtonClick}>
            <BackIcon />
          </button> */}
          {!publicKey && (
            <div className="flex gap-4 -ml-2 text-[#FFA6A6] text-sm items-center">
              <WarningIcon />
              <div>
                <p>Oh no! It looks like you took the wrong path.</p>
                <p>Login with your Node or through Finnie!</p>
              </div>
            </div>
          )}
        </div>
        <div className="w-full md:w-auto flex flex-col items-center">
          <ClaimRewardsTile
            claimMessage={claimMessage}
            tokensClaimed={totalClaimValue}
          />
        </div>
      </section>
    </div>
  );
};
