import { GenericSuccessGraphic } from 'assets';
import { Tile, TokensClaimedCounter } from 'components';
import Lottie from 'lottie-react';
import confettiAnimation from 'assets/confetti.json';
import { FunctionComponent } from 'react';

interface ClaimRewardsTileProps {
  claimMessage: string;
  tokensClaimed: number;
}

export const ClaimRewardsTile: FunctionComponent<ClaimRewardsTileProps> = ({
  claimMessage,
  tokensClaimed,
}) => {
  return (
    <Tile className="w-120 !h-[28rem] md:!h-[25rem] flex flex-col justify-center items-center gap-4 relative">
      {claimMessage ? (
        <div className="pt-11 pb-8">
          <GenericSuccessGraphic className="max-w-[265px] mx-auto w-full mb-5" />
          <p className="w-full font-normal text-lg leading-6 max-w-[390px] mx-auto px-4">
            {claimMessage}
          </p>
          <TokensClaimedCounter
            tokensClaimed={tokensClaimed}
            animate={false}
            fireClaimed={true}
          />
        </div>
      ) : (
        <div className="pt-11 pb-8">
          <GenericSuccessGraphic className="max-w-[265px] mx-auto w-full mb-5" />
          <Lottie
            animationData={confettiAnimation}
            loop={false}
            className="absolute -top-16 pointer-events-none"
          />
          <TokensClaimedCounter
            tokensClaimed={tokensClaimed}
            animate={false}
            fireClaimed={true}
          />
          <p className="float-right mt-2">Claimed so far...</p>
        </div>
      )}
    </Tile>
  );
};
