import { FC, FunctionComponent, ReactNode, useCallback, useState } from 'react';
import { Button } from 'components';
import { MethodValidationProps } from 'types';

// Ethereum wallet related imports
import { ethers, verifyMessage } from 'ethers';

console.log(ethers);

import axios from 'axios';
import { API_URL } from 'config';
import Spinner from './common/Spinner';

export const EthereumValidation: FC<MethodValidationProps> = ({
  onSuccess,
  publicKey,
  setSuccessMessage,
}) => {
  const [error, setError] = useState<ReactNode>(null);
  const koiiPublicKey = publicKey;

  return (
    <div className="flex flex-col gap-8">
      <p className="text-2xl mt-3">Ethereum Verification</p>
      <div className="text-sm max-w-xs mx-auto">
        <p>Scan your wallet to get KOII!</p>
        <p className="mt-1">Based on NFTs owned</p>{' '}
      </div>
      <ul>
        <li>1. Access your wallet.</li>
        <li>2. Bind your ETH wallet.</li>
        <li>3. Enjoy your rewards!</li>
      </ul>
      <SignETHMessage
        koiiPublicKey={koiiPublicKey}
        onSuccess={onSuccess}
        setError={setError}
        setMsg={setSuccessMessage}
      />

      {error && <p className="text-error text-xs mt-0">{error}</p>}
    </div>
  );
};

interface SignETHMessageProps {
  koiiPublicKey: string;
  onSuccess: () => Promise<void>;
  setError: (error: ReactNode) => void;
  setMsg?: (value: string) => void;
}

const SignETHMessage: FC<SignETHMessageProps> = ({
  koiiPublicKey,
  onSuccess,
  setError,
  setMsg,
}) => {
  const [hasBeenClicked, setHasBeenClicked] = useState(false);

  const onClick = useCallback(async () => {
    // don't allow multiple clicks
    setHasBeenClicked(true);
    try {
      // connect to wallet
      let signer = null;

      let provider;

      if (!window.ethereum) {
        console.log('MetaMask not installed');
        setError(
          <>
            <p>
              MetaMask seems to not be installed! If not then please install
              MetaMask from{' '}
              <a
                href="https://metamask.io/download.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                here
              </a>
              .
            </p>
            <p>
              If metamask is installed and not detected another wallet extension
              may be blocking it. See{' '}
              <a
                href="https://docs.koii.network/faq/questions/troubleshooting/#q-metamask-not-detected-i-tried-to-connect-metamask-in-faucet-it-says-its-not-installed-but-it-is-installed"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                tutorial
              </a>
            </p>
          </>
        );
      } else {
        // Connect to the MetaMask EIP-1193 object. This is a standard
        // protocol that allows Ethers access to make all read-only
        // requests through MetaMask.

        provider = new ethers.BrowserProvider(window.ethereum);

        // sign message

        // It also provides an opportunity to request access to write
        // operations, which will be performed by the private key
        // that MetaMask manages for the user.
        signer = await provider.getSigner();
        // signer = new Wallet(id("test"))
        // example
        // Wallet {
        //   address: '0xC08B5542D177ac6686946920409741463a15dDdB',
        //   provider: null
        // }

        const message = `Koii wants you to connect your Ethereum account:\n${signer.address}\n\n with your Koii account ${koiiPublicKey}\n\n No payment, just verify you own the wallet\n\n`;

        // Signing the message
        const signature = await signer.signMessage(message);
        // '0xefc6e1d2f21bb22b1013d05ecf1f06fd73cdcb34388111e4deec58605f3667061783be1297d8e3bee955d5b583bac7b26789b4a4c12042d59799ca75d98d23a51c'

        // Validating a message; notice the address matches the signer -- probably do on backend
        const verify = verifyMessage(message, signature);
        console.log(verify);
        // '0xC08B5542D177ac6686946920409741463a15dDdB'

        // Prepare the payload with the public key and the signature
        const payload = {
          ethereumPublicKey: signer.address,
          signature: signature,
          koiiPublicKey: koiiPublicKey,
          network: 'Ethereum',
        };
        console.log('Payload: ', payload);

        // Send the payload to the backend
        const response = await axios.post(`${API_URL}/bind-wallet`, payload, {
          // default is to throw error for anything other than 200
          validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        });
        console.log(response.data.message);

        if (response.status == 200) {
          console.log('Successfully connected Ethereum!');
          setMsg?.(response.data.message);
          await onSuccess();
        } else {
          setError(response.data.message);
          setHasBeenClicked(false); // can retry if there is error
        }
      }
    } catch (error: any) {
      console.log(error.data);
      if (
        error.info &&
        error.info.error &&
        error.info.error.data === 'No imported Ethereum account'
      ) {
        setError('No imported Ethereum account, install MetaMask!');
      } else {
        setError('Something went wrong');
      }

      setHasBeenClicked(false); // can retry if there is error
    }
  }, [koiiPublicKey]);

  return (
    <Button
      className="!p-0 m-auto w-50 h-14 mb-0"
      onClick={onClick}
      disabled={hasBeenClicked}
    >
      {hasBeenClicked ? <Spinner /> : 'Connect Wallet!'}
    </Button>
  );
};
