import { FunctionComponent, ReactNode } from 'react';

interface TileProps {
  children: ReactNode;
  className?: string;
  onSelect?: () => void;
  disabled?: boolean;
}

export const Tile: FunctionComponent<TileProps> = ({
  children,
  className,
  onSelect,
  disabled,
}) => {
  const bgClass = disabled ? 'bg-white/[.05]' : 'bg-white/[.1]';
  return (
    <div
      className={`max-w-full ${bgClass} rounded-lg border-solid border-white/[.35] border-2 shadow-tile text-center ${className}`}
      onClick={onSelect}
    >
      {children}
    </div>
  );
};
