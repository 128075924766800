import { FunctionComponent, ReactNode } from 'react';
import { PendingIcon, CheckCircleIcon } from 'assets';
import { ValidationStatus } from 'types';
import { Tile } from './common';

interface MethodTileProps {
  name: string;
  status: ValidationStatus;
  Icon: FunctionComponent;
  onSelect: () => void;
  isSelected: boolean;
}

type Statuses = Partial<
  Record<ValidationStatus, { className: string; content: ReactNode }>
>;

const chains = ['solana', 'ethereum'];

export const MethodTile: FunctionComponent<MethodTileProps> = ({
  name,
  status,
  Icon,
  onSelect,
  isSelected,
}) => {
  const statuses: Statuses = {
    CLAIMED: {
      className: '!bg-methods-claimed',
      content: (
        <>
          <p className="text-sm">COMPLETED</p>
          <div className="flex justify-center">
            <CheckCircleIcon />
          </div>
          <p className="text-sm uppercase">{name}</p>
        </>
      ),
    },
    NOT_CLAIMED: {
      className: '!bg-methods-notClaimed',
      content: (
        <>
          <h5 className="text-2xl mt-3">
            {chains.includes(name)
              ? 'Connect'
              : name === 'fire'
              ? 'x5 $OFIRE'
              : 'x2 KOII'}
          </h5>
          <p className="text-sm uppercase">
            {name === 'fire' ? 'fuel your fire' : name}
          </p>
          <div className="flex justify-center">
            <Icon />
          </div>
        </>
      ),
    },
    FAILED: {
      className: '!bg-methods-notClaimed',
      content: (
        <>
          <p className="text-sm">BOUND</p>
          <p className="text-sm uppercase">{name}</p>
          <div className="flex justify-center">
            <Icon />
          </div>
        </>
      ),
    },
    IN_PROGRESS: {
      className: '!bg-methods-inProgress/[.4]',
      content: (
        <>
          <p className="text-sm">PENDING</p>
          <div className="flex justify-center">
            <PendingIcon />
          </div>
          <p className="text-sm uppercase">{name}</p>
        </>
      ),
    },
    BOUND: {
      className: '!bg-methods-bound',
      content: (
        <>
          <h5 className="text-xl mt-3">CONNECTED</h5>
          <p className="text-sm uppercase">{name}</p>
          <div className="flex justify-center">
            <Icon />
          </div>
        </>
      ),
    },
  };

  const backgroundColor = isSelected
    ? '!bg-methods-current'
    : statuses[status]?.className;

  return (
    <Tile
      className={`${backgroundColor} cursor-pointer flex flex-col gap-2 p-5 max-w-[17.5rem] h-[11.6rem] duration-300`}
      onSelect={onSelect}
    >
      {statuses[status]?.content}
    </Tile>
  );
};
