import { FunctionComponent } from 'react';
import { Tile } from './common';
import Tooltip from './common/ToolTip';

interface DisabledMethodTileProps {
  name: string;
  Icon: FunctionComponent;
}

export const DisabledMethodTile: FunctionComponent<DisabledMethodTileProps> = ({
  name,
  Icon,
}) => {
  const locked = ['solana', 'ethereum', 'fire'];
  const message = {
    chains: 'To unlock verify with another method first!',
    workingOnIt: 'This feature is being worked on!',
  };
  const chainsEnabled = true;
  return (
    <Tile
      className="flex flex-col gap-2 p-5 max-w-[17.5rem] h-[11.6rem] duration-300"
      disabled
    >
      <h5 className="text-2xl mt-3 text-dark-grey">
        {' '}
        {locked.includes(name) ? 'Locked' : 'Coming Soon'}{' '}
        <Tooltip
          message={
            chainsEnabled && locked.includes(name)
              ? message.chains
              : message.workingOnIt
          }
        />
      </h5>
      <p className="text-sm uppercase text-dark-grey">{name}</p>
      <div className="flex justify-center">
        <Icon />
      </div>
    </Tile>
  );
};
