import { FunctionComponent } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Header } from 'components/Header';
import { MountainsDecoration, WavesGraphic } from 'assets';

export const Layout: FunctionComponent = () => {
  const { pathname } = useLocation();

  const BackgroundDecoration =
    pathname === '/faucet' || pathname === '/faucet/rewards-history'
      ? WavesGraphic
      : MountainsDecoration;
  const outletContainerClasses = `mb-auto xl:my-auto ${
    pathname !== '/faucet' && pathname !== '/faucet/rewards-history' && 'pb-48'
  } ${
    pathname !== '/faucet' &&
    pathname !== '/faucet/rewards-history' &&
    pathname !== '/' &&
    '!my-auto'
  } z-10`;

  return (
    <div className="min-h-screen text-white bg-gradient-green-blue flex flex-col justify-between relative ">
      <Header />
      <div className={outletContainerClasses}>
        <Outlet />
      </div>
      <BackgroundDecoration className="w-full absolute bottom-0" />
    </div>
  );
};
