import { FunctionComponent, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  TwitterIcon,
  DiscordIcon,
  EmailIcon,
  FireIcon,
  SolanaIcon,
  EthereumIcon,
} from 'assets';
import {
  MethodTile,
  NavigationButtons,
  CurrentValidationTile,
  ClaimableTokens,
  TokensClaimedCounter,
  DisabledMethodTile,
  ReferralCode,
} from 'components';
import { getFaucetStatus, postUserVisit } from 'services';
import { ValidationStatus, MethodName } from 'types';
import { useWalletContext } from 'contexts';
import { useQueryParam } from 'hooks';

interface Status {
  discord: ValidationStatus;
  email: ValidationStatus;
  // github: ValidationStatus;
  twitter: ValidationStatus;
  referral: ValidationStatus;
  solana: ValidationStatus;
  ethereum: ValidationStatus;
  fire: ValidationStatus;
}

interface Method {
  name: MethodName;
  status: ValidationStatus;
  Icon: FunctionComponent;
  disabled?: boolean;
}

const initialStatus: Status = {
  discord: ValidationStatus.NOT_CLAIMED,
  email: ValidationStatus.NOT_CLAIMED,
  // github: ValidationStatus.NOT_CLAIMED,
  twitter: ValidationStatus.NOT_CLAIMED,
  referral: ValidationStatus.NOT_CLAIMED,
  solana: ValidationStatus.NOT_CLAIMED,
  ethereum: ValidationStatus.NOT_CLAIMED,
  fire: ValidationStatus.NOT_CLAIMED,
};

export const Faucet: FunctionComponent = () => {
  const [status, setStatus] = useState<Status>(initialStatus);
  const [currentMethod, setCurrentMethod] = useState<MethodName>();
  const [animateCounter, setAnimateCounter] = useState<boolean>(false);
  const [displayNodeScreen, setDisplayNodeScreen] = useState<boolean>(false);
  const [isReferralSuccess, setIsReferralSuccess] = useState<boolean>(false);
  const [displaySuccessScreen, setDisplaySuccessScreen] =
    useState<boolean>(false);
  const [chainRewards, setChainRewards] = useState<string>('');

  const navigate = useNavigate();

  const {
    publicKey,
    setPublicKey,
    hasVerifiedRecaptcha,
    setDiscordCode,
    setGithubCode,
  } = useWalletContext();

  const methods: Method[] = [
    { name: 'email', status: status.email, Icon: EmailIcon },
    {
      name: 'discord',
      status: status.discord,
      Icon: DiscordIcon,
    },
    { name: 'fire', status: status.fire, Icon: FireIcon, disabled: true },
    {
      name: 'twitter',
      status: status.twitter,
      Icon: TwitterIcon,
      disabled: true,
    },
    {
      name: 'solana',
      status: status.solana,
      Icon: SolanaIcon,
      disabled: true,
    },
    {
      name: 'ethereum',
      status: status.ethereum,
      Icon: EthereumIcon,
      disabled: true,
    },
  ];

  const tokensClaimedbyRegularMethods = methods.reduce(
    (accumulator, current) =>
      current.status === ValidationStatus.CLAIMED && current.name !== 'fire'
        ? (accumulator || 1) * 2
        : accumulator,
    0
  );

  const fireClaimed = methods.some(
    (method) =>
      method.name === 'fire' && method.status === ValidationStatus.CLAIMED
  );

  const blockChainClaimed = methods.reduce(
    (accumulator, current) =>
      current.status === ValidationStatus.BOUND
        ? accumulator || 1
        : accumulator,
    0
  );

  // const isEmailValidated = status.email === ValidationStatus.CLAIMED;
  // const isTwitterValidated = status.email === ValidationStatus.CLAIMED;

  const hasValidatedTwitterDiscordAndReferral =
    [status.discord, status.twitter].some(
      (status) => status === ValidationStatus.CLAIMED
    ) && status.referral === ValidationStatus.CLAIMED;
  const tokensClaimedByReferralProgram = hasValidatedTwitterDiscordAndReferral
    ? 5
    : 0;
  const tokensClaimed =
    tokensClaimedByReferralProgram +
    tokensClaimedbyRegularMethods +
    blockChainClaimed;

  const hasClaimedAllMethods = tokensClaimed >= 18;

  // methods not disabled if other methods have been claimed
  if (tokensClaimed > 0) {
    methods.forEach((item) => {
      if (item.name === 'twitter' && item.status !== 'CLAIMED') return;
      item.disabled = false;
    });
  }

  const handleOpenMyNode = () => setDisplayNodeScreen(true);

  const resetMethod = () => setCurrentMethod(undefined);

  const selectMethod = (status: ValidationStatus, name: MethodName) => {
    if (
      [ValidationStatus.NOT_CLAIMED, ValidationStatus.FAILED].includes(status)
    ) {
      setDisplayNodeScreen(false);
      setCurrentMethod(name);
      setDisplaySuccessScreen(false);
      setIsReferralSuccess(false);
      setChainRewards(name);
    }
    // bound status
    if ([ValidationStatus.BOUND].includes(status)) {
      // TODO do stuff
      setCurrentMethod('bound');
      setDisplayNodeScreen(false);
      setDisplaySuccessScreen(false);
      setIsReferralSuccess(false);
      setChainRewards(name);
    }
  };

  const selectReferralMethod = () => selectMethod(status.referral, 'referral');

  const fetchAndSetStatus = useCallback(async () => {
    if (publicKey) {
      const response = await getFaucetStatus(publicKey);
      postUserVisit(publicKey);
      if (response.emailValidation === 'IN_PROGRESS') {
        response.emailValidation = ValidationStatus.NOT_CLAIMED;
      }

      // fetch route wallets

      if (response) {
        setStatus({
          discord: response.discordValidation,
          email: response.emailValidation,
          // Remove the "|| ValidationStatus.NOT_CLAIMED" as soon as we do the db migration adding the field to old accounts records
          twitter: response.twitterValidation,
          referral: response.referral,
          solana: response.solanaValidation || ValidationStatus.NOT_CLAIMED,
          ethereum: response.ethereumValidation || ValidationStatus.NOT_CLAIMED,
          fire: response.fireToken || ValidationStatus.NOT_CLAIMED,
        });
      }
    }
  }, [publicKey]);

  const triggerAnimation = () => {
    setAnimateCounter(true);
    // Reset the animation after 2 seconds so we can re-run it after the next validation
    setTimeout(() => {
      setAnimateCounter(false);
    }, 2000);
  };

  const handleSuccess = async (triggerCounterAnimation = true) => {
    await fetchAndSetStatus();
    resetMethod();
    setDisplaySuccessScreen(true);
    if (triggerCounterAnimation) triggerAnimation();
  };

  const handleReferralSuccess = async () => {
    setIsReferralSuccess(true);
    const shouldTriggerCounterAnimation = [
      status.discord,
      status.twitter,
    ].every((status) => status === ValidationStatus.CLAIMED);
    await handleSuccess(shouldTriggerCounterAnimation);
  };

  useEffect(() => {
    fetchAndSetStatus();
  }, [fetchAndSetStatus]);

  const publicKeyFromOauth = useQueryParam('state');
  const oAuthCode = useQueryParam('code');

  // Verify the user either comes from Discord OAuth or has completed the recaptcha, otherwise redirect to the landing page
  useEffect(() => {
    const userComesFromOauth = !!(oAuthCode && publicKeyFromOauth);

    if (userComesFromOauth) {
      const [method, publicKey] = publicKeyFromOauth.split('-');
      setPublicKey(publicKey);
      const setCode = method === 'discord' ? setDiscordCode : setGithubCode;
      setCode(oAuthCode);
      setCurrentMethod(method as MethodName);
    } else if (!hasVerifiedRecaptcha) {
      const urlToRedirect = `/${publicKey ? `?key=${publicKey}` : ''}`;
      navigate(urlToRedirect);
    }
  }, [
    navigate,
    hasVerifiedRecaptcha,
    publicKey,
    oAuthCode,
    publicKeyFromOauth,
    setPublicKey,
    setDiscordCode,
    setGithubCode,
  ]);

  return (
    <div className="py-4 px-8 lg:px-[4.3rem] xl:px-[8%] 3xl:px-[12%] 4xl:px-[20%] 5xl:px-[25%]">
      <section className="mb-8 flex justify-between flex-col-reverse lg:flex-row">
        <div className="w-full md:max-w-[60rem] md:w-[65%]">
          <h4 className="text-aqua text-5xl md:text-6xl font-bold">
            KOII FAUCET
          </h4>
          <p className="mt-4 text-lg leading-6 max-w-[514px]">
            Discover four distinct methods to confirm your unique identity. Earn
            more tokens for each completed method.
          </p>
        </div>

        <TokensClaimedCounter
          tokensClaimed={tokensClaimed}
          animate={animateCounter}
          fireClaimed={fireClaimed}
        />
      </section>
      <section className="flex flex-col lg:flex-row gap-20 lg:gap-6 items-center h-full m-auto justify-between">
        <div className="grid grid-cols-2 min-w-full sm:min-w-fit w-[90%] lg:w-1/2 max-w-[35.6rem] gap-7">
          {methods.map(({ name, status, Icon, disabled }) =>
            disabled ? (
              <DisabledMethodTile key={name} name={name} Icon={Icon} />
            ) : (
              <MethodTile
                key={name}
                name={name}
                status={status}
                Icon={Icon}
                isSelected={name === currentMethod}
                onSelect={() => selectMethod(status, name)}
              />
            )
          )}
        </div>
        <div className="w-full md:w-auto flex flex-col items-center">
          <CurrentValidationTile
            displaySuccessScreen={displaySuccessScreen}
            isReferralSuccess={isReferralSuccess}
            publicKey={publicKey}
            method={currentMethod}
            reset={resetMethod}
            onSuccessfulValidation={handleSuccess}
            tokensClaimed={tokensClaimed as ClaimableTokens}
            displayNodeScreen={displayNodeScreen}
            network={chainRewards}
            msg={''}
          />
        </div>
      </section>
      <section className="min-w-full sm:min-w-fit w-[90%] lg:w-1/2 max-w-[35.6rem] mx-auto lg:mx-0 mt-5">
        <ReferralCode
          status={status.referral}
          publicKey={publicKey}
          onSuccess={handleReferralSuccess}
          onFocus={selectReferralMethod}
        />
      </section>
      <NavigationButtons
        forceDisplayOptions={hasClaimedAllMethods}
        onOpenMyNode={handleOpenMyNode}
      />
    </div>
  );
};
